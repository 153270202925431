@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  min-width: 0;
  min-height: 0;
  flex-shrink: 0;
}
*::selection {
  @apply bg-brand-500/80 text-white;
}

:root {
  @apply text-[16px] md:text-[14px] overscroll-none;
  color-scheme: light dark;
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}

html,
body {
  @apply flex h-full flex-auto flex-col w-full overscroll-none;
}

.bg-dot-grid {
  background-image: radial-gradient(currentColor 1px, transparent 0);
  background-size: 30px 30px;
}

.bg-polka {
  background-image: radial-gradient(currentColor 0.5px, transparent 0.5px),
    radial-gradient(currentColor 0.5px, transparent 0.5px);
  background-size: 20px 20px;
  background-position:
    0 0,
    10px 10px;
}

.radial-mask {
  mask-image: radial-gradient(
    closest-side at 40% 45%,
    black 0%,
    rgba(0, 0, 0, 0.5) 80%,
    transparent 100%
  );
}

[data-sonner-toast] [data-content] {
  @apply shrink;
}

.marching-ants {
  animation: marching-ants linear 1s infinite;
}

@keyframes marching-ants {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -16;
  }
}

@keyframes shine {
  0% {
    background-position:
      200% 0,
      0 0;
  }
  100% {
    background-position:
      -100% 0,
      0 0;
  }
}

.progress-pulse {
  background-color: currentColor;
  background-image: linear-gradient(
    90deg,
    transparent 20%,
    transparent 48%,
    rgb(255, 255, 255, 0.5) 53%,
    rgb(255, 255, 255, 0.6) 55%,
    transparent 55.5%,
    transparent 100%
  );
  background-size: 150% 100%;
  animation: shine linear 2s infinite;
}

.construction-stripes {
  background-image: repeating-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2) 10px,
    rgba(0, 0, 0, 0.3) 10px,
    rgba(0, 0, 0, 0.3) 20px
  );
}

.text-gradient {
  @apply inline-block text-transparent bg-clip-text;
}

@property --conic-angle {
  syntax: '<angle>';
  inherits: false;
  initial-value: 10deg;
}

.bg-conic {
  --conic-angle: 0deg;
  animation: conic-spin 10s linear infinite;
  background: conic-gradient(
    from var(--conic-angle),
    transparent 0,
    currentColor 20deg,
    transparent 22deg
  );
}

@keyframes conic-spin {
  0% {
    --conic-angle: 0deg;
  }
  100% {
    --conic-angle: 360deg;
  }
}

.animate-stroke {
  stroke-dasharray: 0.5 1000;
  animation: dash 10s linear infinite;
}

.group:nth-of-type(1) .animate-stoke {
  animation-delay: 100ms;
}
.group:nth-of-type(2) .animate-stoke {
  animation-delay: 200ms;
}
.group:nth-of-type(3) .animate-stoke {
  animation-delay: 300ms;
}
.group:nth-of-type(4) .animate-stoke {
  animation-delay: 400ms;
}
.group:nth-of-type(5) .animate-stoke {
  animation-delay: 500ms;
}
.group:nth-of-type(6) .animate-stoke {
  animation-delay: 600ms;
}

@keyframes dash {
  0% {
    stroke-dashoffset: -50;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    stroke-dashoffset: 4;
    opacity: 0;
  }
}

.bg-radial {
  background-image: radial-gradient(var(--tw-gradient-stops));
}

.brand-heading {
  @apply font-brand text-gradient bg-gradient-to-b from-brand-700 to-brand-600 dark:from-white dark:to-brand-300;
}

.brand-underline {
  @apply underline-offset-4 decoration-2;
}
